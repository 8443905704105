<template>
  <footer>
    <div class="top">
      <div class="top-content">
        <div class="left">
          <img src="@/assets/images/logo.svg" alt="" />
          <span class="description">
            The market leader that efficiently provides the quickest access to
            the most pertinent hospitality items on a worldwide level.
          </span>
          <div class="social">
            <a href="#">
              <img src="@/assets/images/icons/linkedin.svg" alt="" />
            </a>
            <a href="#">
              <img src="@/assets/images/icons/instagram.svg" alt="" />
            </a>
            <a href="#">
              <img src="@/assets/images/icons/facebook.svg" alt="" />
            </a>
          </div>
        </div>
        <div class="right">
          <ul>
            <div class="menu">
              <span class="title">PRODUCTS</span>
              <li>
                <a href="#">Zen</a>
              </li>
              <li>
                <a href="#">Compass</a>
              </li>
              <li>
                <a href="#">Cosmos</a>
              </li>
              <li>
                <a href="#">Insights</a>
              </li>
              <li>
                <a href="#">B2C & B2B Booking Platform</a>
              </li>
              <li>
                <a href="#">Central Backoffice</a>
              </li>
            </div>
            <div class="menu">
              <span class="title">RESOURCES</span>
              <li>
                <a href="#">Solutions</a>
              </li>
              <li>
                <a href="#">Customers</a>
              </li>
              <li>
                <a href="#">Partners</a>
              </li>
              <li>
                <a href="#">Developers</a>
              </li>
              <li>
                <a href="#">About Us</a>
              </li>
            </div>
            <div class="menu">
              <span class="title">COMPANY</span>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms and Conditions</a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-content">
        <div class="left">
          <span class="copyright">
            All rights reserved. 2023 Copyright HotelStore.com
          </span>
        </div>
        <div class="right">
          <ul>
            <li>
              <a href="#">Terms of Service</a>
            </li>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
            <li>
              <a href="#">Cookies</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "HSFooter",
};
</script>

<style lang="scss" scoped>
footer {
  background-color: #fdf9f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top {
    margin: 64px 0;
    &-content {
      width: $width;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        img {
          margin-bottom: 24px;
          width: 200px;
        }
        .description {
          display: flex;
          color: $secondary;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin-bottom: 24px;
        }
        .social {
          a {
            margin-right: 24px;
            img {
              width: auto;
            }
          }
        }
      }
      .right {
        flex: 2;
        display: flex;
        justify-content: flex-end;
        ul {
          display: flex;
          flex-wrap: wrap;
          .menu {
            display: flex;
            flex-direction: column;
            margin-left: 36px;
            margin-bottom: 16px;
            .title {
              display: flex;
              margin-bottom: 20px;
              font-size: 12px;
              font-weight: 700;
              line-height: 12px;
              color: #a3a3a3;
            }
            li {
              a {
                display: flex;
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
                color: $secondary;
                padding-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #e4e4e4;
    &-content {
      display: flex;
      justify-content: space-between;
      width: $width;
      margin: 16px auto;
      .right {
        ul {
          display: flex;
          align-items: center;
          li {
            a {
              color: $secondary;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              padding: 0 12px;
            }
          }
        }
      }
    }
  }
  @media (max-width: $width-s) {
    .top {
      margin: 32px 0;
      &-content {
        flex-direction: column;
        .left {
          align-items: center;
          .description {
            text-align: center;
          }
        }
        .right {
          justify-content: center;
          ul {
            flex-direction: column;
            align-items: center;
            .menu {
              width: 100%;

              margin: 0;
              padding-bottom: 24px;
              margin-bottom: 24px;
              &:not(:last-child) {
                border-bottom: 1px solid rgba($text, 0.2);
              }
              .title {
                display: flex;
                justify-content: center;
                text-align: center;
              }
              li {
                a {
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
    .bottom {
      &-content {
        flex-direction: column;
        .left {
          display: flex;
          justify-content: center;
          .copyright {
            text-align: center;
          }
        }
        .right {
          margin-top: 12px;
          ul {
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
