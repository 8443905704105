<template>
  <header class="container">
    <div class="header-left">
      <div class="logo">
        <img src="@/assets/images/logo.svg" alt="Logo" />
      </div>
    </div>
    <div class="header-right">
      <ul class="menu">
        <li>
          <router-link :to="{ name: 'products' }">Products</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'about' }">About</router-link>
        </li>
        <li>
          <router-link class="button secondary" :to="{ name: 'contact' }">
            Contact Us
          </router-link>
        </li>
      </ul>
      <ul class="mobile-menu">
        <a
          href="#"
          class="mobile-menu-button"
          @click="showMobileMenu = !showMobileMenu"
        >
          <img src="@/assets/images/icons/hamburger.png" alt="" />
        </a>
      </ul>
      <div class="mobile-menu-content" v-if="showMobileMenu">
        <div class="wrapper">
          <div class="header">
            <img src="@/assets/images/logo.svg" alt="" />
            <a
              href="#"
              class="mobile-menu-button"
              @click="showMobileMenu = !showMobileMenu"
            >
              <img src="@/assets/images/icons/close.png" alt="" />
            </a>
          </div>
          <ul class="menu">
            <li>
              <router-link :to="{ name: 'products' }">Products</router-link>
            </li>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="#" class="button secondary">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HSHeader",
  data() {
    return {
      showMobileMenu: false,
    };
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 88px;
  .header-left {
    .logo {
      img {
        width: 100%;
      }
    }
  }
  .header-right {
    .menu {
      display: flex;
      align-items: center;
      li {
        a {
          color: $text;
          padding: 8px 24px;
          font-weight: 500;
          &.button {
            color: $white;
            height: 26px;
          }
        }
      }
    }
    .mobile-menu {
      display: none;
    }
  }
  /* Mobil Cihazlar */
  @media (max-width: $width-s) {
    .header-right {
      .menu {
        display: none;
      }
      .mobile-menu {
        display: flex !important;
        &-button {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          border-radius: 6px;
          align-items: center;
          img {
            width: 30px;
          }
        }
      }
      .mobile-menu-content {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $white;
        .wrapper {
          .header {
            height: 88px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            margin: 0 auto;
          }
          .menu {
            margin: 0 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            li {
              padding: 12px 0;
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $text;
                padding: 0 24px;
                font-weight: 500;
                font-size: 20px;
                &.button {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
